import { Control, RegisterOptions } from 'react-hook-form';

export enum ValueType {
  STRING = 'STRING',
  NUMBER = 'NUMBER',
  BOOLEAN = 'BOOLEAN',
  ARRAY = 'ARRAY',
  OBJECT = 'OBJECT',
  DATE = 'DATE',
}

export enum NodeType {
  // _COPY = '_COPY',
  _OPERATORS = '_OPERATORS',
  _CONDITIONS = '_CONDITIONS',
  _AGGREGATORS = '_AGGREGATORS',
  _FIELDS = '_FIELDS',
  _FUNCTIONS = '_FUNCTIONS',
  _CONSTANTS = '_CONSTANTS',
  _IF = '_IF',
  _CONDITIONAL = '_CONDITIONAL',
  _THEN = '_THEN',
  _ELSE = '_ELSE',
  _SWITCH = '_SWITCH',
  _CASE = '_CASE',
}

export interface GenericNode {
  id: string;
  name: string;
  valueType?: ValueType;
  acceptedValueTypes?: ValueType[];
}

export type NodeDescription = Omit<GenericNode, 'id'>;

export interface INode extends GenericNode {
  nodes?: INode[];
}

export interface IField extends NodeDescription {
  value: string;
  displayName: string;
  entityName: string;
  translatableEntityName: string;
}

export interface IConstant extends NodeDescription {
  value: string | number | boolean;
}

export interface IAggregator extends NodeDescription {}

export interface IOperator extends NodeDescription {}

export interface ICondition extends NodeDescription {
  nodesNo: number;
  _type: 'NODE' | 'SPEL';
  params: NodeDescription[];
  _sameTypeNodes: boolean;
}

export interface IFunction extends NodeDescription {
  nodesNo: number;
  params: NodeDescription[];
}

export interface INodeBlock extends NodeDescription {
  type: NodeType;
  requiredNodes?: NodeType[];
}

export interface DropdownItemChild {
  name: string;
  value: string;
}

export interface DropdownItem {
  type: NodeType | '_COPY';
  name: string;
  value: string | DropdownItemChild[];
}

export interface DropdownClickProps {
  value: string;
  type: NodeType;
}

export interface ICompactNode {
  id: string;
  name: string;
  nodes: ICompactNode[];
  type: NodeType | '_INTERMEDIARY';
  value?: string | number | boolean;
}

interface IBaseExpressionBuilderProps {
  entityKey?: string;
  restrictedValueType?: ValueType;
  exportType?: 'OBJECT' | 'JSON';
}

export interface IExpressionBuilderProps extends IBaseExpressionBuilderProps {
  initialState: any;
  onChange: (state: any) => void;
}

export interface IUncontrolledExpressionBuilderProps extends IBaseExpressionBuilderProps {
  validate?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
  control: Control;
  name: string;
}
