import React, { useContext, useState } from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, Dropdown } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ContextHeaderState } from '../header/header';

export const NavDropdown = props => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toggle = () => setIsOpen(!isOpen);
  const { disableNavClick, setDisableNavClick } = useContext(ContextHeaderState);

  function openHeaderMenu() {
    setDisableNavClick('open');
    setIsOpen(true);
  }

  return (
    <React.Fragment>
      {disableNavClick === 'close' ? (
        <FontAwesomeIcon style={{ cursor: 'pointer' }} icon={props.icon} className="menu-closed" onClick={() => openHeaderMenu()} />
      ) : (
        <Dropdown
          nav
          inNavbar
          id={props.id}
          data-cy={props['data-cy']}
          isOpen={isOpen}
          direction={props.direction ? props.direction : 'down'}
        >
          <DropdownToggle onClick={toggle} nav caret className="d-flex align-items-center">
            <FontAwesomeIcon icon={props.icon} />
            <span>{props.name}</span>
          </DropdownToggle>
          <DropdownMenu end className={props.className}>
            {props.children}
          </DropdownMenu>
        </Dropdown>
      )}
    </React.Fragment>
  );
};

export const NavDropdownHover = props => {
  const [isOpen, setIsOpen] = useState(false);
  const onMouseEnter = () => setIsOpen(true);
  const onMouseLeave = () => setIsOpen(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <Dropdown
      inNavbar
      id={props.id}
      data-cy={props['data-cy']}
      onMouseOver={onMouseEnter}
      onMouseLeave={onMouseLeave}
      isOpen={isOpen}
      toggle={toggle}
      direction={props.direction ? props.direction : 'down'}
    >
      <DropdownToggle nav caret className="d-flex align-items-center">
        <FontAwesomeIcon icon={props.icon} />
        <span>{props.name}</span>
      </DropdownToggle>
      <DropdownMenu end className={props.className}>
        {props.children}
      </DropdownMenu>
    </Dropdown>
  );
};
